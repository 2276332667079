import { z } from 'zod'

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const taxesSchema = z.object({
  taxYear: z.string(),
  document: z.string(),
  investments: z.object({
    label: z.string(),
    href: z.array(z.string().url()),
  }),
  ownershipType: z.string(),
  issuer: z.string(),
  status: z.object({
    label: z.string(),
    href: z.string().optional(),
  }),
})

export type Taxes = z.infer<typeof taxesSchema>
