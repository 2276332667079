import { z } from 'zod'

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const transactionsSchema = z.object({
  date: z.string(),
  transactionStatus: z.string(),
  investment: z.string(),
  investmentLink: z.string(),
  foeType: z.string(),
  foeName: z.string(),
  issuer: z.string(),
  amount: z.number(),
  shares: z.union([z.number(), z.null()]).optional(),
  purchasePrice: z.number().optional(),
})

export type TransactionsTypes = z.infer<typeof transactionsSchema>
