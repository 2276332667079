import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select-filter'
import { cn } from '@/lib/utils'
import { Column, Table } from '@tanstack/react-table'
import { Taxes } from '../data/schema'

interface FilterType {
  table: Table<any>
  columnName: string
}
interface MetaType {
  filterLabel?: string
  reverseSort?: boolean
}

export function Filter({ table, columnName }: FilterType) {
  const column = table.getColumn(columnName) as Column<any, unknown>
  const columnFilterValue = column.getFilterValue()

  const values = Array.from(
    new Set(
      table.getCoreRowModel().flatRows.map((row) => {
        const value = row.getValue(columnName) as string | Taxes['investments']
        if (typeof value === 'object') {
          return value?.label
        }
        return value
      })
    )
  ).sort((a, b) =>
    (column.columnDef.meta as MetaType)?.reverseSort
      ? b.localeCompare(a)
      : a.localeCompare(b)
  )

  return (
    <Select
      value={columnFilterValue?.toString()}
      onValueChange={(value) => column.setFilterValue(value)}
      defaultValue="all"
    >
      <SelectTrigger className="h-[38px]">
        <span
          className={cn(
            columnFilterValue?.toString() === 'all' || !columnFilterValue
              ? ''
              : 'font-medium'
          )}
        >
          <SelectValue
            placeholder={
              (column.columnDef.meta as MetaType)?.filterLabel || 'All'
            }
          />
        </span>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">
          {(column.columnDef.meta as MetaType)?.filterLabel || 'All'}
        </SelectItem>
        {values.map((value) => (
          <SelectItem value={value} key={value}>
            {value}
          </SelectItem>
        ))}{' '}
      </SelectContent>
    </Select>
  )
}
