import { useQuery } from '@tanstack/react-query'
import { getTokens } from '../shared/_api'

type Token = {
  id: string
  tokenValue: string
}

export const useTokensDataState = () => {
  return useQuery({
    queryKey: ['tokens'],
    queryFn: async () => {
      const data = await getTokens()
      if (data) {
        const sortedData = data
          .sort((a: Token, b: Token) => a.id.localeCompare(b.id))
          .reduce((acc: any, item: any) => {
            acc[item.id] = item.tokenValue
            return acc
          }, {})

        return sortedData
      }
    },
    staleTime: Infinity,
  })
}
