import { StorageHelper } from '@aws-amplify/core'
import { awsConfig } from '../routes/shared/_auth'
import { Amplify } from 'aws-amplify'

export const moneyMask = (value: string | number | undefined) => {
  value = String(value)?.replace('.', '').replace(',', '').replace(/\D/g, '')

  const options = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  return new Intl.NumberFormat('en-US', options).format(Number(value))
}

export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]

export const dateOut = (date: string) => {
  // format date to YYYY-MM-DD
  if (!date) return undefined
  const parts = date.split('/')
  return parts[2] + '-' + parts[0] + '-' + parts[1]
}

export const dateIn = (date: string) => {
  //format Date to MM-DD-YYYY
  if (!date) return ''
  const parts = date.split('-')
  return parts[1] + '/' + parts[2] + '/' + parts[0]
}

export const decodePayload = (jwtToken: string) => {
  const payload = jwtToken.split('.')[1]
  try {
    // Replace base64url characters with base64 characters
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/')
    // Pad with '=' to make the length a multiple of 4
    const padded = base64.padEnd(
      base64.length + ((4 - (base64.length % 4)) % 4),
      '='
    )
    // Decode base64 string
    const decoded = atob(padded)
    return JSON.parse(decoded)
  } catch (err) {
    console.error('Failed to decode JWT payload:', err)
    return {}
  }
}

export const calculateClockDrift = (
  iatAccessToken: number,
  iatIdToken: number
) => {
  const now = Math.floor((new Date() as any) / 1000)
  const iat = Math.min(iatAccessToken, iatIdToken)
  return now - iat
}

export const setTokens = (
  accessToken: string,
  idToken: string,
  refreshToken: string
) => {
  const storage = new StorageHelper().getStorage()

  // Clear any existing data with prefix CognitoIdentityServiceProvider.
  for (const key in storage) {
    if (key.startsWith('CognitoIdentityServiceProvider.')) {
      storage.removeItem(key)
    }
  }

  const idTokenData = decodePayload(idToken)
  const accessTokenData = decodePayload(accessToken)

  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.LastAuthUser',
    idTokenData['cognito:username']
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.idToken',
    idToken
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.accessToken',
    accessToken
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.refreshToken',
    refreshToken
  )
  storage.setItem(
    'CognitoIdentityServiceProvider.' +
      awsConfig.Auth.userPoolWebClientId +
      '.' +
      idTokenData['cognito:username'] +
      '.clockDrift',
    '' + calculateClockDrift(accessTokenData['iat'], idTokenData['iat']) + ''
  )

  Amplify.configure({ ...awsConfig, storage: storage })
}
