import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { updateAccount, addAccount } from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'
import { useAccountDataState } from '../_accountDataState'
import { useInvestDataState } from '../_investDataState'
import { usePersonalInfoDataState } from '../_personalInfoDataState'
import { useTokensDataState } from '../_tokensDataState'
import { AccountTypes, IndividualAccountTypes } from '../../shared/_types'

import { IndividualForm } from '../../shared/Forms/IndividualForm'
import Loader from '../../../components/Loader'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'

const IndividualAccountScreen = () => {
  const { t } = useTranslation()
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()
  const { data: personalInfoData } = usePersonalInfoDataState()
  const { data: tokenData } = useTokensDataState()

  const backLocation = `/invest/${investData?.investment?.id}/account`

  const updateAccountMutation = useMutation({
    mutationFn: (data: IndividualAccountTypes) => {
      const existingAccount = accountData.ia_options?.find(
        (account: any) => account.type === 'individual'
      )
      console.log(existingAccount)
      const newData = {
        type: 'individual',
        primary_member: { ...data },
      }
      return existingAccount
        ? updateAccount(
            investData?.opportunity?.id,
            existingAccount.id,
            newData
          )
        : addAccount(investData?.opportunity?.id, newData)
    },
    onMutate: async (newIndividualAccount: IndividualAccountTypes) => {
      await queryClient.cancelQueries({
        queryKey: ['account', 'invest'],
      })
      const previousAccountData = queryClient.getQueryData(['account'])
      queryClient.setQueryData<AccountTypes>(['account'], (oldData) => {
        return { ...oldData, current_account: { ...newIndividualAccount } }
      })
      return { previousAccountData }
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['account'],
      })
      queryClient.invalidateQueries({
        queryKey: ['personalInfo'],
      })
      if (
        tokenData?.state_allowlist &&
        !JSON.parse(tokenData?.state_allowlist)?.includes(
          data?.primary_member?.address?.state
        )
      ) {
        nav(`/invest/${investData.opportunity.id}/account/failed`)
        return
      }
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          investment_account: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    },
    onError: (error: any, _variables: any, context) => {
      queryClient.setQueryData(['account'], context?.previousAccountData)
      console.error(error.message)
      setServerError(error.message)
    },
  })

  const onSubmit = (data: IndividualAccountTypes) => {
    setServerError('')
    updateAccountMutation.mutate(data)
  }

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={0} currentStep={3} totalStepCount={3} />
      ) : (
        <Stepper
          currentSection={0}
          currentStep={3}
          totalStepCount={4}
          isPledge={
            investData.opportunity.status.toLowerCase() === 'open for pledging'
          }
        />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('common:ownershipType')}
          </h1>
          {accountData && personalInfoData ? (
            <>
              <p className="mb-6 italic text-content-black">
                {t('common:individual')}
              </p>
              <p className="mb-6 text-16 text-content-black">
                {t('account:confirmIndividualInfo')}
              </p>
              <div className="xl:">
                <IndividualForm
                  data={
                    accountData.current_account?.type === 'individual'
                      ? accountData.current_account?.primary_member
                      : personalInfoData
                  }
                  backLocation={backLocation}
                  onSubmit={onSubmit}
                  disabled={updateAccountMutation.isLoading}
                  hasApprovedInvestments={
                    personalInfoData.has_approved_investments
                  }
                />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default IndividualAccountScreen
