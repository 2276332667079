import { useQuery } from '@tanstack/react-query'

import { getStrings } from '../shared/_api'
import { useAuthDataState } from './_authDataState'

export const useStringsDataState = () => {
  const { data: authData } = useAuthDataState()

  return useQuery({
    queryKey: ['strings'],
    queryFn: getStrings,
    enabled: !!authData,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
