import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { cn } from '@/lib/utils'

import Footer from '../shared/Footer'
import Nav from './shared/Nav'

import { useTokensDataState } from '../Invest/_tokensDataState'

import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { TooltipProvider } from '@/components/ui/tooltip'

import { MOCK_MESSAGES_DATA } from './Messages/MOCK_MESSAGES_DATA'

MOCK_MESSAGES_DATA.map((message) => {
  return message
})

interface DashboardProps {
  defaultLayout?: number[] | undefined
  defaultCollapsed?: boolean
}

const Dashboard = ({ defaultCollapsed = false }: DashboardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const location = useLocation()

  const { data: tokenData, isFetched: isTokenDataFetched } =
    useTokensDataState()

  useEffect(() => {
    // feature flag check
    if (tokenData?.ff_dashboard === 'no' && isTokenDataFetched) {
      window.location.href = 'https://www.realtymogul.com'
    }
  }, [tokenData, isTokenDataFetched])

  useEffect(() => {
    // execute on location change
    console.log('Location changed!', location.pathname)
  }, [location])

  return (
    <TooltipProvider delayDuration={0}>
      <div className="flex h-full w-full flex-col items-center">
        <div className="w-full h-[69px] text-2xl border-b border-b-slate-100 flex p-4 items-center">
          RealtyMogul
        </div>
        <div className="w-full md:min-h-[80vh] box-border py-6 px-8 xl:max-w-[1800px]">
          <ResizablePanelGroup
            direction="horizontal"
            onLayout={(sizes: number[]) => {
              document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                sizes
              )}`
            }}
            className="h-full items-stretch"
          >
            <ResizablePanel
              collapsedSize={3}
              collapsible={true}
              minSize={14}
              maxSize={14}
              onCollapse={() => {
                setIsCollapsed(true)
                document.cookie = `react-resizable-panels:nav:collapsed=true`
              }}
              onExpand={() => {
                setIsCollapsed(false)
                document.cookie = `react-resizable-panels:nav:collapsed=false`
              }}
              className={cn(
                isCollapsed &&
                  'min-w-[50px] transition-all duration-300 ease-in-out',
                'h-[80vh]'
              )}
            >
              <div
                className={cn(
                  'flex h-full items-center justify-center select-none'
                )}
              >
                <Nav
                  isCollapsed={isCollapsed}
                  links={[
                    {
                      title: 'Investments',
                      icon: 'fa-light fa-chart-line',
                      location: '/dashboard/investments',
                      active: location.pathname.includes('investments'),
                    },
                    {
                      title: 'Messages',
                      icon: 'fa-light fa-envelope',
                      location: '/dashboard/messages',
                      active: location.pathname === '/dashboard/messages',
                    },
                    {
                      title: 'Tax Center',
                      icon: 'fa-light fa-files',
                      location: '/dashboard/taxes',
                      active: location.pathname.includes('taxes'),
                    },
                    {
                      title: 'Transactions',
                      icon: 'fa-light fa-rectangle-list',
                      location: '/dashboard/transactions',
                      active: location.pathname.includes('transactions'),
                    },
                  ]}
                  links2={[
                    {
                      title: 'Bank Accounts',
                      icon: 'fa-light fa-bank',
                      location: '/dashboard/bank-accounts',
                      active: location.pathname.includes('bank-accounts'),
                    },
                    {
                      title: 'Ownership Types',
                      icon: 'fa-light fa-user-group',
                      location: '/dashboard/ownership-types',
                      active: location.pathname.includes('ownership-types'),
                    },
                    {
                      title: 'Profile',
                      icon: 'fa-light fa-user',
                      location: '/dashboard/profile',
                      active: location.pathname.includes('profile'),
                    },
                  ]}
                />
              </div>
            </ResizablePanel>
            <ResizablePanel minSize={30}>
              <Outlet />
            </ResizablePanel>
          </ResizablePanelGroup>
        </div>
        <div className="w-full bg-black">
          <Footer />
        </div>
      </div>
    </TooltipProvider>
  )
}

export default Dashboard
