import { ReactNode, useState, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

interface InputProps {
  label?: string | ReactNode | undefined
  placeholder?: string
  helpText?: string | ReactNode
  errorMessage: string | FieldError | undefined
  onBlur: () => void
  onChange: () => void
  value: string | undefined
}

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    const {
      label,
      helpText,
      placeholder,
      errorMessage,
      onBlur,
      onChange,
      value,
    } = props
    return (
      <div className="relative">
        <span
          className={`mb-2 block text-base font-normal ${
            errorMessage ? 'text-alert' : 'text-content-black'
          }`}
        >
          {label}
        </span>
        <input
          className={`mb-2 h-[42px] w-full rounded border focus:outline-none focus:ring-1 focus:border-primary ${
            errorMessage ? 'border-alert' : 'border-input'
          } px-3 py-1.5 text-16`}
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          ref={ref}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
        <div
          className="absolute right-3 top-1/2"
          onClick={(e) => {
            e.preventDefault()
            setShowPassword((current) => !current)
          }}
        >
          <i className="fa-regular fa-eye"></i>
        </div>
        {props.errorMessage && (
          <p className="mb-1 text-xs text-alert">
            {typeof props.errorMessage === 'string'
              ? props.errorMessage
              : props.errorMessage.message}
          </p>
        )}
        {helpText && (
          <div className="text-xs text-content-light">{helpText}</div>
        )}
      </div>
    )
  }
)

export default PasswordInput
