import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'
import { createAndLoginTestUser, loginAsUser } from '../shared/_api'
import PasswordInput from '../../components/PasswordInput'
import TextInput from '../../components/TextInput'
import NumberInput from '@/components/NumberInput'
import { setTokens } from '@/utils/helpers'

interface LoginFormTypes {
  username?: string | undefined
  password?: string | undefined
  userID?: string | undefined
}

const Login = () => {
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { control, handleSubmit } = useForm<LoginFormTypes>({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const { control: drupalControl, handleSubmit: drupalSubmit } =
    useForm<LoginFormTypes>({
      defaultValues: {
        userID: '',
      },
    })

  const onSubmit = handleSubmit(async (data: LoginFormTypes) => {
    try {
      setIsSubmitting(true)
      setError('')
      await Auth.signIn((data.username || '').toLowerCase(), data.password)
      delete data.username
      delete data.password
      queryClient.setQueryData(['overrideData'], () => {
        return {
          aboutYou: { ...data },
          ...data,
        }
      })
    } catch (error) {
      if (typeof error === 'string') {
        setError(error)
      } else if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      queryClient.invalidateQueries({ queryKey: ['auth'] })
      setIsSubmitting(false)
    }
  })

  const onDrupalSubmit = drupalSubmit(async (data: LoginFormTypes) => {
    if (!data.userID) return
    try {
      setIsSubmitting(true)
      setError('')
      const responseData = await loginAsUser(data.userID)
      console.log(responseData.auth)
      setTokens(
        responseData.auth.AccessToken,
        responseData.auth.IdToken,
        responseData.auth.RefreshToken
      )
    } catch (error) {
      if (typeof error === 'string') {
        setError(error)
      } else if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      queryClient.invalidateQueries({ queryKey: ['auth'] })
      setIsSubmitting(false)
    }
  })

  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full">
        <div className="max-w-[1400px] primary bg-white px-6 py-4 mx-auto">
          <div className="flex flex-row items-center">
            <img className="mr-4" src="/rm.svg" />
            <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
              RealtyMogul
            </span>
          </div>
        </div>
      </div>
      <form
        className="flex h-screen w-full flex-col items-center justify-center"
        onSubmit={onSubmit}
      >
        <h1 className="text-xl mb-6">
          Please log in again to securely invest in this opportunity.
        </h1>
        <div className="w-full max-w-lg p-3 space-y-6">
          <Controller
            name="username"
            control={control}
            rules={{
              required: 'is required',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Email is invalid',
              },
            }}
            render={({ field, fieldState }) => (
              <TextInput
                label="Email"
                errorMessage={fieldState.error}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: 'is required' }}
            render={({ field, fieldState }) => (
              <PasswordInput
                label="Password"
                errorMessage={fieldState.error}
                {...field}
              />
            )}
          />
          <button
            className="mt-6 mb-6 h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            Sign In
          </button>
          {window.location.origin !== `https://my.realtymogul.com` && (
            <>
              <div className="flex justify-center mb-4">- OR -</div>
              <button
                className="h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
                disabled={isSubmitting}
                onClick={(e) => {
                  e.preventDefault()
                  setIsSubmitting(true)
                  createAndLoginTestUser()
                    .then(() => queryClient.invalidateQueries(['auth']))
                    .finally(() => setIsSubmitting(false))
                }}
              >
                {isSubmitting ? (
                  <span className="fa-lg block mr-4">
                    <i className="fa-spin fa-solid fa-spinner-third mr-4"></i>
                    Creating user...
                  </span>
                ) : (
                  'Create and Log In as Test User'
                )}
              </button>
              <div className="flex justify-center mb-4">- OR -</div>
              <Controller
                name="userID"
                control={drupalControl}
                rules={{ required: 'is required' }}
                render={({ field }) => (
                  <NumberInput label="User ID" {...field} />
                )}
              />
              <button
                className="mt-6 mb-6 h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
                onClick={onDrupalSubmit}
                disabled={isSubmitting}
              >
                Login with Drupal User ID #
              </button>
            </>
          )}

          {error !== '' && (
            <div className="mt-2 rounded-full bg-bg-light px-4 py-2">
              <i className="fa-solid fa-circle-exclamation mr-2 text-alert"></i>
              {error}
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default Login
