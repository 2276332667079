import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useAmountDataState, useAmountMutation } from '../_amountDataState'
import { moneyMask } from '../../../utils/helpers'
import FormFooter from '../../shared/FormFooter'
import MoneyInput from '../../../components/MoneyInput'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import ErrorAlert from '../../../components/ErrorAlert'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Loader from '../../../components/Loader'
import { findRoute } from '../../../rules/findRoute'

interface FormType {
  amount: string
}

const PrivatePlacementAmountScreen = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: amountData } = useAmountDataState()
  const { isLoading, mutateAsync: mutateAmount } = useAmountMutation()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormType>()

  useEffect(() => {
    if (investData?.opportunity.io_type === 'reit') {
      nav(`/invest/${investData.opportunity.id}/amount/reit`)
    }
    if (amountData) {
      reset({ amount: amountData.amount || amountData.amount_minimum })
    }
  }, [investData, nav, amountData, reset])

  const onSubmit = handleSubmit(async (data) => {
    console.log(errors, data)
    mutateAmount({
      amount: Number(String(data.amount).replace(/\D/g, '')),
    })
      .then(() => {
        findRoute({
          ...investData,
          investment: {
            ...investData?.investment,
            amount: Number(String(data.amount).replace(/\D/g, '')),
          },
          investment_state: {
            ...investData?.investment_state,
            investment_account: 'submitted',
            amount: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  // const backLocation = location?.state?.from
  //   ? location?.state?.from
  //   : `/invest/${investData?.investment?.id}/account`

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={0} currentStep={4} totalStepCount={4} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('common:investmentAmount')}
          </h1>
          {amountData ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                {t('amount:howMuchToInvest')}
              </p>
              <form onSubmit={onSubmit} className="mb-14 xl:">
                <div className="mb-6">
                  {Object.keys(errors).length > 0 && <ErrorAlert />}
                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      required: 'is required',
                      validate: {
                        min: (value) => {
                          if (
                            amountData?.amount_minimum &&
                            Number(String(value).replace(/\D/g, '')) <
                              Number(amountData?.amount_minimum)
                          )
                            return t('min', {
                              ns: 'amount',
                              min: `$${moneyMask(amountData?.amount_minimum)}`,
                            })
                        },
                        step: (value) => {
                          if (
                            amountData?.amount_step &&
                            Number(String(value).replace(/\D/g, '')) %
                              Number(amountData?.amount_step) !==
                              0
                          ) {
                            return t('step', {
                              ns: 'amount',
                              step: `$${moneyMask(amountData?.amount_step)}`,
                            })
                          }
                        },
                        max: (value) => {
                          if (amountData?.amount_maximum) {
                            if (
                              Number(String(value).replace(/\D/g, '')) >
                              Number(amountData?.amount_maximum)
                            )
                              return t('max', {
                                ns: 'amount',
                                max: `$${moneyMask(
                                  amountData?.amount_maximum
                                )}`,
                              })
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <MoneyInput
                        label={t('common:investmentAmount')}
                        placeholder="$"
                        helpText={t('minMax', {
                          ns: 'amount',
                          min: `$${moneyMask(amountData?.amount_minimum)}`,
                          max: `$${moneyMask(amountData?.amount_maximum)}`,
                        })}
                        error={fieldState.error}
                        {...field}
                      />
                    )}
                  />
                </div>
                <FormFooter submitFunc={onSubmit} disabled={isLoading} />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default PrivatePlacementAmountScreen
