import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { Table } from '@tanstack/react-table'

import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface DataTablePaginationProps<TData> {
  table: Table<TData>
}

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {
  const getStartRow = () => {
    return (
      table.getState().pagination.pageIndex *
        table.getState().pagination.pageSize +
      1
    )
  }

  const getEndRow = () => {
    return (table.getState().pagination.pageIndex + 1) *
      table.getState().pagination.pageSize >=
      table.getFilteredRowModel().rows.length
      ? table.getFilteredRowModel().rows.length
      : (table.getState().pagination.pageIndex + 1) *
          table.getState().pagination.pageSize
  }

  return (
    <div className="flex items-center justify-between px-4 rounded-b-md border border-t-0 p-2">
      <div className="flex-1 text-sm text-muted-foreground">
        {`${getStartRow()} - ${getEndRow()} `}
        of {table.getFilteredRowModel().rows.length} transactions
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <div className="flex items-center justify-center text-sm font-medium">
            Page{' '}
            <Select
              value={`${table.getState().pagination.pageIndex + 1}`}
              onValueChange={(value) => {
                table.setPageIndex(Number(value) - 1)
              }}
            >
              <SelectTrigger className="h-8 mx-2 w-[60px]">
                <SelectValue
                  placeholder={table.getState().pagination.pageIndex + 1}
                />
              </SelectTrigger>
              <SelectContent side="top">
                {Array.from(
                  { length: table.getPageCount() },
                  (_, i) => i + 1
                ).map((pageNumber) => (
                  <SelectItem key={pageNumber} value={`${pageNumber}`}>
                    {pageNumber}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>{' '}
            of {table.getPageCount()}
          </div>
          <Button
            variant="ghost"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
