import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { isBefore, isEqual, isValid, parse } from 'date-fns'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'

import {
  updateAccount,
  addAccount,
  getSignedS3Url,
  uploadToSignedS3,
} from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'
import { AccountTypes, TrustAccountTypes } from '../../shared/_types'
import { useInvestDataState } from '../_investDataState'
import { useAccountDataState } from '../_accountDataState'
import { useTokensDataState } from '../_tokensDataState'
import { dateIn, dateOut } from '../../../utils/helpers'

import Checkbox from '../../../components/Checkbox'
import CountryInput from '../../../components/CountryInput'
import DateInput from '../../../components/DateInput'
import ErrorAlert from '../../../components/ErrorAlert'
import FileUpload from '../../../components/FileUpload'
import FormFooter from '../../shared/FormFooter'
import InfoModal from '../../../components/InfoModal'
import Radio from '../../../components/Radio'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Sidebar from '../Sidebar'
import StateDropdown from '../../../components/StateDropdown'
import Stepper from '../../../components/Stepper'
import TextInput from '../../../components/TextInput'
import TINInput from '../../../components/TINInput'

const TrustAccountScreen = () => {
  const [showAddNew, setShowAddNew] = useState(false)
  const [trustAccount, setTrustAccount] = useState('')
  const [lockFields, setLockFields] = useState(false)
  const [trust_agreement_later, setTrust_agreement_later] = useState(false)
  const [use_primary_investor_contact, setUse_primary_investor_contact] =
    useState(false)
  const [serverError, setServerError] = useState('')

  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()
  const { data: tokenData } = useTokensDataState()
  const { data: accountData } = useAccountDataState()
  const { t } = useTranslation()

  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TrustAccountTypes>({
    defaultValues: {
      trustAccount: '',
      trust_name: '',
      title_within_trust: '',
      subtype: 'revocable',
      date_of_formation: '',
      tin: '',
      trust_agreement_uuid: '',
      phone_number: '',
      jurisdiction_of_registration: 'AL',
      use_primary_investor_contact: false,
      is_disregarded_entity: false,
      address: {
        street: '',
        street2: '',
        city: '',
        zip_code: '',
        country: 'US',
        state: 'AL,',
      },
    },
  })

  const watchAddress = watch('address')

  useEffect(() => {
    if (!accountData) return
    if (trustAccount === 'addNew') {
      setLockFields(false)
    } else {
      setLockFields(
        !!accountData?.ia_options.find((account: TrustAccountTypes) => {
          return account.uuid === trustAccount
        })?.has_an_approved_investment
      )
    }
  }, [trustAccount, accountData])

  useEffect(() => {
    if (
      !accountData?.ia_options ||
      trustAccount === '' ||
      updateAccountMutation.isLoading
    )
      return
    if (trustAccount && trustAccount !== 'addNew') {
      const matchedTrustAccount = accountData?.ia_options.find(
        (account: TrustAccountTypes) => {
          return account.uuid === trustAccount
        }
      )

      if (matchedTrustAccount) {
        const cleanedTrustAccount = Object.fromEntries(
          Object.entries(matchedTrustAccount).filter(
            ([_key, value]) => value !== null
          )
        )
        setUse_primary_investor_contact(
          !!cleanedTrustAccount.use_primary_investor_contact
        )
        reset({
          ...cleanedTrustAccount,
          date_of_formation: dateIn(
            cleanedTrustAccount?.date_of_formation as string
          ),
        })
      }
    } else {
      setUse_primary_investor_contact(false)
      reset({
        trust_name: '',
        title_within_trust: '',
        subtype: 'revocable',
        date_of_formation: '',
        tin: '',
        trust_agreement_uuid: '',
        phone_number: '',
        jurisdiction_of_registration: 'AL',
        use_primary_investor_contact: false,
        is_disregarded_entity: false,
        address: {
          street: '',
          street2: '',
          city: '',
          zip_code: '',
          country: 'US',
          state: 'AL,',
        },
      })
    }
    setShowAddNew(true)
  }, [trustAccount, accountData, reset])

  const updateAccountMutation = useMutation({
    mutationFn: (data: TrustAccountTypes) => {
      if (use_primary_investor_contact) {
        delete data.address
      }
      const newData = {
        ...Object.fromEntries(
          Object.entries(data).filter(([_key, value]) => value !== '')
        ),
        date_of_formation: dateOut(data.date_of_formation),
        type: 'foe_trust',
      }
      return trustAccount !== 'addNew'
        ? updateAccount(investData?.investment?.id, trustAccount, newData)
        : addAccount(investData?.investment?.id, newData)
    },
    onMutate: async (newTrustAccount: TrustAccountTypes) => {
      await queryClient.cancelQueries({
        queryKey: ['account', 'invest'],
      })
      const previousAccountData = queryClient.getQueryData(['account'])
      queryClient.setQueryData<AccountTypes>(['account'], (oldData) => {
        return { ...oldData, current_account: { ...newTrustAccount } }
      })
      return { previousAccountData }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['account'],
      })
      if (
        tokenData?.state_allowlist &&
        !JSON.parse(tokenData?.state_allowlist).includes(
          data.jurisdiction_of_registration
        )
      ) {
        nav(`/invest/${investData.opportunity.id}/account/failed`)
        return
      }
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          investment_account: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    },
    onError: (error: any, _variables: any, context) => {
      queryClient.setQueryData(['account'], context?.previousAccountData)
      console.error(error.message)
      setServerError(error.message)
    },
  })

  const onSubmit = handleSubmit((data) => {
    setServerError('')
    updateAccountMutation.mutateAsync(data)
  })

  return (
    accountData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={0} currentStep={3} totalStepCount={3} />
        ) : (
          <Stepper
            currentSection={0}
            currentStep={3}
            totalStepCount={4}
            isPledge={
              investData.opportunity.status.toLowerCase() ===
              'open for pledging'
            }
          />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold text-content-black">
              {t('common:ownershipType')}
            </h1>
            <p className="mb-6 italic text-content-black">
              {t('common:trust')}
            </p>
            <p className="mb-6 text-16 text-content-black">
              {showAddNew
                ? t('account:trustDetails')
                : t('account:selectTrust')}
            </p>
            {Object.keys(errors).length > 0 && <ErrorAlert />}
            <form onSubmit={onSubmit}>
              <div className="mb-6">
                <p className="mb-2 text-16 text-content-black">
                  {t('account:trustAccount')}
                </p>
                <Controller
                  name="trustAccount"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="mb-6">
                      <div
                        className={`flex flex-col gap-px ${
                          fieldState.error && 'border border-alert'
                        }`}
                      >
                        {accountData?.ia_options &&
                          accountData?.ia_options.map(
                            (account: TrustAccountTypes, index: number) =>
                              account.type === 'foe_trust' && (
                                <Radio
                                  key={index}
                                  {...field}
                                  value={account.uuid}
                                  label={
                                    account.trust_name +
                                    ' - ' +
                                    account.title_within_trust
                                  }
                                  onChange={() => {
                                    field.onChange(account.uuid)
                                    setTrustAccount(account.uuid)
                                  }}
                                />
                              )
                          )}
                        <Radio
                          {...field}
                          value="addNew"
                          label={t('account:addNewTrust')}
                          onChange={() => {
                            field.onChange('addNew')
                            setTrustAccount('addNew')
                          }}
                        />
                      </div>
                      {fieldState.error && (
                        <span className="text-xs text-alert">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />
                {showAddNew && (
                  <div>
                    <p className="mb-6 text-[19px] font-medium">
                      {t('common:trust')}
                    </p>
                    <div className="mb-6 flex flex-col gap-6">
                      <Controller
                        name="trust_name"
                        control={control}
                        rules={{ required: 'is required' }}
                        disabled={lockFields}
                        render={({ field, fieldState }) => (
                          <TextInput
                            label={t('account:nameTrust')}
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="title_within_trust"
                        control={control}
                        disabled={lockFields}
                        rules={{ required: 'is required' }}
                        render={({ field, fieldState }) => (
                          <TextInput
                            label={
                              <span>
                                {t('account:titleWithinTrust')}{' '}
                                <InfoModal
                                  modalContent={
                                    <div className="p-8">
                                      <h2 className="mb-6 text-xl font-medium text-content-black">
                                        {t('account:titleWithinTrust')}
                                      </h2>
                                      <p className="font-sans text-16 font-normal text-content-black">
                                        {t('account:titleWithinTrustModal')}
                                      </p>
                                    </div>
                                  }
                                />
                              </span>
                            }
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                      <div className="text-16 text-content-black">
                        <div className="mb-2">
                          {t('account:typeOfTrust')}{' '}
                          <InfoModal
                            modalContent={
                              <div className="p-8 text-16 font-normal text-content-black">
                                <h2 className="mb-6 text-xl font-medium text-content-black">
                                  {t('account:typeOfTrust')}
                                </h2>
                                <p className="mb-6">
                                  {t('account:typeOfTrustModal1')}
                                </p>
                                <p className="mb-6">
                                  {t('account:typeOfTrustModal2')}
                                </p>
                                <p>
                                  <Trans i18nKey="account:realEstateAdviceDisclaimer" />
                                </p>
                              </div>
                            }
                          />
                        </div>
                        <Controller
                          name="subtype"
                          control={control}
                          disabled={lockFields}
                          render={({ field, fieldState }) => (
                            <div
                              className={`flex flex-col gap-px ${
                                field.disabled && 'pointer-events-none'
                              } ${fieldState.error && 'border border-alert'}`}
                            >
                              <Radio
                                {...field}
                                value="revocable"
                                label={t('account:revocableTrust')}
                                checked={field.value === 'revocable'}
                              />
                              <Radio
                                {...field}
                                value="irrevocable"
                                label={t('account:irrevocableTrust')}
                                checked={field.value === 'irrevocable'}
                              />
                            </div>
                          )}
                        />
                      </div>
                      <Controller
                        name="date_of_formation"
                        control={control}
                        disabled={lockFields}
                        rules={{
                          required: 'is required',
                          validate: {
                            isValidDate: (value) =>
                              isValid(parse(value, 'MM/dd/yyyy', new Date())) ||
                              'is not a valid date',
                            isTodayOrEarlier: (value) => {
                              const inputDate = parse(
                                value,
                                'MM/dd/yyyy',
                                new Date()
                              )
                              const today = new Date()
                              return (
                                isBefore(inputDate, today) ||
                                isEqual(inputDate, today) ||
                                'Date must not be in the future.'
                              )
                            },
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <DateInput
                            label={t('account:dateOfFormation')}
                            placeholder="mm/dd/yyyy"
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="tin"
                        control={control}
                        disabled={lockFields}
                        rules={{
                          required: 'is required',
                          validate: {
                            isLongEnough: (value) => {
                              const tin = value.replace(/-/g, '')
                              return tin.length === 9 || 'is not a valid TIN'
                            },
                            // isValidTIN: (value) => {
                            // TODO: Look up valid TIN Numbers
                            // },
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TINInput
                            label={
                              <span>
                                {t('account:tinLabel')}{' '}
                                <InfoModal
                                  modalContent={
                                    <div className="p-8">
                                      <h2 className="mb-6 text-xl font-medium text-content-black">
                                        {t('account:tinLabel')}
                                      </h2>
                                      <p className="mb-6 font-sans text-16 font-normal text-content-black">
                                        {t('account:tinModalTrust')}
                                      </p>
                                    </div>
                                  }
                                />
                              </span>
                            }
                            disabled={lockFields}
                            placeholder="__-_______"
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="is_disregarded_entity"
                        control={control}
                        disabled={lockFields}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            label={
                              <span>
                                {t('account:isDisregardedEntity')}{' '}
                                <InfoModal
                                  modalContent={
                                    <div className="p-8">
                                      <h2 className="mb-6 text-xl font-medium text-content-black">
                                        {t('account:disregardedEntity')}
                                      </h2>
                                      <p className="font-sans text-16 font-normal text-content-black">
                                        <Trans i18nKey="account:deModal" />
                                        <br />
                                        <br />
                                        <Trans i18nKey="account:realEstateAdviceDisclaimer" />
                                      </p>
                                    </div>
                                  }
                                />
                              </span>
                            }
                            checked={field.value === true}
                            {...field}
                            errorMessage={fieldState.error}
                          />
                        )}
                      />
                      <Controller
                        name="jurisdiction_of_registration"
                        control={control}
                        rules={{ required: 'is required' }}
                        render={({ field, fieldState }) => (
                          <StateDropdown
                            errorMessage={fieldState.error}
                            label={t('account:jurisdictionOfRegistration')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="use_primary_investor_contact"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            {...field}
                            onChange={() => {
                              field.onChange(!field.value)
                              setUse_primary_investor_contact(
                                !use_primary_investor_contact
                              )
                            }}
                            checked={field.value === true}
                            label={t('account:trustSameAddress')}
                            errorMessage={fieldState.error}
                          />
                        )}
                      />
                      {!use_primary_investor_contact && (
                        <div>
                          <div className="mb-6">
                            <span
                              className={`mb-2 block text-base font-normal ${
                                errors?.phone_number?.message
                                  ? 'text-alert'
                                  : 'text-content-black'
                              }`}
                            >
                              {t('common:phoneNumber')}
                            </span>
                            <PhoneInput
                              name="phone_number"
                              className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                                errors?.phone_number
                                  ? 'border-alert'
                                  : 'border-input'
                              } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
                              defaultCountry="US"
                              countryOptionsOrder={['US']}
                              control={control}
                              initialValueFormat="national"
                              limitMaxLength={true}
                              rules={{
                                required: 'is required',
                                validate: {
                                  isValidPhoneNumber: (value: string) =>
                                    isValidPhoneNumber(value) ||
                                    'Please enter a valid phone number.',
                                },
                              }}
                            />
                            {errors?.phone_number?.message && (
                              <p className="mb-1 text-xs text-alert">
                                {errors?.phone_number?.message}
                              </p>
                            )}
                          </div>
                          <div className="mb-6">
                            <CountryInput
                              register={register('address.country')}
                              errorMessage={errors?.address?.country?.message}
                            />
                          </div>
                          <div className="mb-2">
                            <Controller
                              name="address.street"
                              control={control}
                              rules={{ required: 'is required' }}
                              render={({ field, fieldState }) => (
                                <TextInput
                                  label={t('common:address')}
                                  errorMessage={fieldState.error}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-6">
                            <Controller
                              name="address.street2"
                              control={control}
                              render={({ field, fieldState }) => (
                                <TextInput
                                  placeholder={t('common:address2')}
                                  errorMessage={fieldState.error}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-6">
                            <Controller
                              name="address.city"
                              control={control}
                              rules={{ required: 'is required' }}
                              render={({ field, fieldState }) => (
                                <TextInput
                                  label={t('common:city')}
                                  errorMessage={fieldState.error}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-6 flex gap-3">
                            <div className="w-1/2">
                              <Controller
                                name="address.state"
                                control={control}
                                rules={{
                                  required:
                                    watchAddress?.country === 'US'
                                      ? 'is required'
                                      : false,
                                }}
                                render={({ field, fieldState }) =>
                                  watchAddress?.country === 'US' ? (
                                    <StateDropdown
                                      errorMessage={fieldState?.error?.message}
                                      label={t('common:state')}
                                      {...field}
                                    />
                                  ) : (
                                    <TextInput
                                      label={
                                        watchAddress?.country === 'US'
                                          ? t('common:state')
                                          : t('common:stateProvinceRegion')
                                      }
                                      errorMessage={fieldState?.error?.message}
                                      {...field}
                                    />
                                  )
                                }
                              />
                            </div>
                            <div className="w-1/2">
                              <Controller
                                name="address.zip_code"
                                control={control}
                                rules={{ required: 'is required' }}
                                render={({ field, fieldState }) => (
                                  <TextInput
                                    label={
                                      watchAddress?.country === 'US'
                                        ? t('common:zipCode')
                                        : t('common:postalCode')
                                    }
                                    errorMessage={fieldState.error}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {!trust_agreement_later && (
                        <>
                          <div className="mb-6 text-[19px] font-medium">
                            {t('account:trustAgreementCopy')}
                          </div>
                          <div className="mb-6">
                            <Controller
                              name="trust_agreement_uuid"
                              control={control}
                              rules={{ required: 'File is required' }}
                              render={({ field }) => (
                                <FileUpload
                                  {...field}
                                  accept=".pdf"
                                  label={t('common:uploadDocument')}
                                  uploadFileFunction={async (file: File) => {
                                    const signedS3Resp = await getSignedS3Url({
                                      fileName: file.name,
                                      type: 'trust_agreement',
                                    })
                                    if (signedS3Resp.status === 200) {
                                      const { fields, url, s3_obj_id } =
                                        signedS3Resp.data
                                      const uploadResp = await uploadToSignedS3(
                                        file,
                                        url,
                                        fields
                                      )
                                      console.log(uploadResp)
                                      setValue(
                                        'trust_agreement_uuid',
                                        s3_obj_id
                                      )
                                    }
                                  }}
                                />
                              )}
                            />
                            {errors?.trust_agreement_uuid?.message && (
                              <span className="mb-1 text-xs text-alert">
                                {errors.trust_agreement_uuid.message}
                              </span>
                            )}
                            <p className="mt-4 text-center text-[13px]">
                              {t('common:pdfOnly')}
                            </p>
                          </div>
                        </>
                      )}
                      <div>
                        <Checkbox
                          name="trust_agreement_later"
                          onChange={() => {
                            setTrust_agreement_later(!trust_agreement_later)
                            setValue('trust_agreement_uuid', '')
                          }}
                          label={t('account:trustFileLater')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <FormFooter
                backLocation={`/invest/${investData?.investment?.id}/account`}
                submitFunc={onSubmit}
                disabled={updateAccountMutation.isLoading || !showAddNew}
              />
              {serverError && (
                <ServerError
                  serverError={serverError}
                  id={investData?.investment?.id}
                />
              )}
            </form>
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default TrustAccountScreen
