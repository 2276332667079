import { useRef, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import SignaturePad from 'react-signature-canvas'
import SignatureCanvas from 'react-signature-canvas'
import { Trans, useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useAccountDataState } from '../_accountDataState'
import { useAgreementsDataState } from '../_agreementsDataState'
import { useTenThirtyOneDataState } from '../_tenThirtyOneDataState'
import { AgreementCheckboxesTypes } from '../../shared/_types'
import {
  getDocusign,
  getSignedS3Url,
  postUploadSignature,
  uploadToSignedS3,
} from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'

import FormFooter from '../../shared/FormFooter'
import Stepper from '../../../components/Stepper'
import Checkbox from '../../../components/Checkbox'
import ErrorAlert from '../../../components/ErrorAlert'
import Sidebar from '../Sidebar'
import Loader from '../../../components/Loader'
import ServerError from '../../../components/ErrorAlert/ServerError'

const parseCheckbox = ({ text, link, link_text }: AgreementCheckboxesTypes) => {
  const str = text.replace(
    '${link}',
    `<a href="${link}" target="_blank" style="color: #176DB6">${link_text}</a>`
  )
  return <div dangerouslySetInnerHTML={{ __html: str }} />
}

const SignatureScreen = () => {
  const location = useLocation()
  const hasError = location.state?.error
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()
  const { data: agreementData } = useAgreementsDataState()
  const { data: tenThirtyOneData } = useTenThirtyOneDataState()
  const { t } = useTranslation()

  const { isLoading, data: docusignData } = useQuery({
    queryKey: ['docusign'],
    queryFn: async () => {
      const data = await getDocusign(investData?.investment?.id)
      if (data.docusign_status === 'signed') {
        nav(
          tenThirtyOneData?.investment_uuid
            ? `/invest/${investData.opportunity.id}/verification`
            : `/invest/${investData.opportunity.id}/funding`
        )
      }
      return {
        ...data,
      }
    },
    enabled: agreementData?.agreement_type === 'docusign',
    retry: 20,
    staleTime: Infinity,
  })

  const signatureRef = useRef<SignatureCanvas>(null)

  const formatIntoPng = () => {
    if (signatureRef.current) {
      const dataURL = signatureRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png')
      const data = atob(dataURL.substring('data:image/png;base64,'.length)),
        asArray = new Uint8Array(data.length)

      for (let i = 0, len = data.length; i < len; ++i) {
        asArray[i] = data.charCodeAt(i)
      }

      return new Blob([asArray.buffer], { type: 'image/png' })
    }
  }

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors, isDirty },
  } = useForm()

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    getSignedS3Url({
      fileName: 'signature.png',
      type: 'signature',
    }).then((signedS3Resp) => {
      const { fields, url, s3_obj_id } = signedS3Resp.data
      uploadToSignedS3(data.signature, url, fields).then(() => {
        postUploadSignature(investData?.investment?.id, {
          signature_uuid: s3_obj_id,
        })
          .then(() => {
            findRoute({
              ...investData,
              investment_state: {
                ...investData.investment_state,
                agreement: 'submitted',
              },
            }).then((route) => {
              queryClient.invalidateQueries(['invest'])
              nav(`/invest/${investData.opportunity.id}/${route.type}`)
            })
          })
          .catch((error) => {
            console.error(error.message)
            setServerError(error.message)
          })
      })
    })
  })

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={2} currentStep={1} totalStepCount={3} />
      ) : (
        <Stepper currentSection={2} currentStep={1} totalStepCount={5} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('signature:agreeAndSign')}
          </h1>
          {hasError && (
            <ErrorAlert
              text={
                <span>
                  {t('signature:signError')}{' '}
                  <a
                    href="mailto:investor-help@realtymogul.com"
                    className="text-primary"
                  >
                    investor-help@realtymogul.com
                  </a>
                  .
                </span>
              }
            />
          )}
          {agreementData && tenThirtyOneData ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                {t('signature:checkBelow')}
              </p>
              <p className="mb-2 text-16 text-content-black">
                {t('signature:investmentAgreement')}
              </p>
              {agreementData.agreement_type === 'checkbox' && (
                <form onSubmit={onSubmit} className="xl:">
                  <div className="mb-6 flex flex-col gap-1">
                    {Object.keys(errors).length > 0 && (
                      <ErrorAlert text="Please check all of the boxes and sign below:" />
                    )}
                    {agreementData.checkboxes &&
                      agreementData.checkboxes.map(
                        (checkbox: AgreementCheckboxesTypes, index: number) => (
                          <Controller
                            key={`checkbox-${index}`}
                            name={`checkbox-${index}`}
                            control={control}
                            rules={{ required: 'is required' }}
                            render={({ field, fieldState }) => (
                              <Checkbox
                                {...field}
                                label={parseCheckbox(checkbox)}
                                errorMessage={fieldState.error}
                              />
                            )}
                          />
                        )
                      )}
                  </div>
                  <p className="mb-6 pr-6 text-[19px] font-medium">Signature</p>
                  <p className="mb-6 text-16 text-content-black">
                    {agreementData.force_draw
                      ? 'Please draw your signature below.'
                      : 'Please draw your signature below or click on the button to sign.'}
                  </p>

                  {/* needed to load font for signature pad */}
                  <p className="font-signature">&nbsp;</p>

                  <div className="mb-20" data-cy="signature-pad">
                    <Controller
                      name="signature"
                      control={control}
                      rules={{ required: 'is required' }}
                      render={({ field, fieldState }) => (
                        <>
                          <SignaturePad
                            ref={signatureRef}
                            onEnd={() => field.onChange(formatIntoPng())}
                            canvasProps={{
                              className: `w-full h-40 border mb-4 ${
                                fieldState.error
                                  ? 'border-alert'
                                  : 'border-content-black'
                              }`,
                            }}
                          />
                          {!agreementData.force_draw && (
                            <div className="flex justify-end">
                              <button
                                className="text-white bg-primary border-action border-2 rounded px-2 py-2 mr-2"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const canvas =
                                    signatureRef?.current?.getCanvas()
                                  const ctx = canvas?.getContext('2d')

                                  if (ctx && canvas) {
                                    ctx.clearRect(
                                      0,
                                      0,
                                      canvas?.width,
                                      canvas?.height
                                    )
                                    ctx.font = '36px adobe-handwriting-ernie'
                                    ctx.textAlign = 'center'
                                    ctx.textBaseline = 'hanging'
                                    const centerX = canvas?.clientWidth / 2
                                    const centerY = canvas?.clientHeight / 2
                                    ctx.fillText(
                                      `${accountData.current_account?.primary_member?.first_name} ${accountData.current_account?.primary_member?.last_name}`,
                                      centerX,
                                      centerY
                                    )
                                    field.onChange(formatIntoPng())
                                  }
                                }}
                              >
                                Click to Sign
                              </button>
                              <button
                                className="px-2 py-2 mr-2 text-primary"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const canvas =
                                    signatureRef?.current?.getCanvas()
                                  const ctx = canvas?.getContext('2d')

                                  if (ctx && canvas) {
                                    ctx.clearRect(
                                      0,
                                      0,
                                      canvas?.width,
                                      canvas?.height
                                    )
                                    resetField('signature')
                                  }
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </form>
              )}
              {agreementData.agreement_type === 'docusign' && (
                <>
                  {!docusignData?.sign_url && !isLoading && (
                    <ErrorAlert
                      text={
                        <span>
                          {t('signature:docusignError')}{' '}
                          <a
                            href="mailto:investor-help@realtymogul.com"
                            className="text-primary"
                          >
                            investor-help@realtymogul.com
                          </a>
                          .
                        </span>
                      }
                    />
                  )}
                  <div
                    className={`mb-6 relative bg-bg-lighter p-4 xl:p-6 ${
                      !docusignData?.sign_url && 'opacity-50'
                    }`}
                  >
                    {isLoading ? (
                      <div className="flex justify-center py-[60px] w-full">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div className="mb-6 flex items-center justify-between">
                          <h2 className="max-w-[66%] text-16 font-bold text-content-black">
                            {t('signature:reviewAndSignYourAgreement')}
                          </h2>
                          <span className="h-[27px mr-2 w-[72px]">
                            <img src="/docusign.svg" />
                          </span>
                        </div>
                        <p className="mb-6 text-16 text-content-black">
                          {t('signature:docusignText')}
                        </p>
                        <a
                          href={docusignData?.sign_url}
                          className="block h-10 mb-6 w-full rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] text-center leading-10"
                        >
                          {t('signature:reviewAndSign')}
                        </a>
                        <p className="text-16 text-content-black">
                          <Trans i18nKey="signature:docusignProblems" />{' '}
                          <a
                            className="text-primary"
                            href="mailto:investor-help@realtymogul.com"
                          >
                            investor-help@realtymogul.com
                          </a>
                          .
                        </p>
                      </>
                    )}
                  </div>
                </>
              )}
              {agreementData.agreement_type === 'checkbox' && (
                <>
                  <FormFooter
                    submitFunc={onSubmit}
                    buttonText="Continue"
                    disabled={!isDirty}
                  />
                  {serverError && (
                    <ServerError
                      serverError={serverError}
                      id={investData?.investment?.id}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default SignatureScreen
