import { ColumnDef } from '@tanstack/react-table'

import { TransactionsTypes } from '../data/schema'
import { DataTableColumnHeader } from './data-table-column-header'

export const columns: ColumnDef<TransactionsTypes>[] = [
  {
    accessorKey: 'date',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({ row }) => {
      const date = row.getValue('date') as TransactionsTypes['date']
      return (
        <div className="font-medium text-base">
          {new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </div>
      )
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: 'transactionStatus',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Transaction" />
    ),
    cell: ({ row }) => {
      return (
        <div className="font-medium text-base">
          {row.getValue('transactionStatus')}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Transactions',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue(
        'transactionStatus'
      ) as TransactionsTypes['transactionStatus']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'investment',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Investment" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-[4px]">
          <a
            className="underline cursor-pointer text-base"
            href={row.getValue('investmentLink')}
            target="_blank"
          >
            {row.getValue('investment')}
          </a>
          <div className="text-[#8C8C8C] text-base">
            {row.getValue('issuer')}
          </div>
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Investments',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue(
        'investment'
      ) as TransactionsTypes['investment']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'ownershipType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ownership Type" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-[4px]">
          <div className="text-base">{row.getValue('foeName')}</div>
          <div className="text-[#8C8C8C]">{row.getValue('foeType')}</div>
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Ownership Types',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue(
        'foeName'
      ) as TransactionsTypes['foeName']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Amount"
        className="text-end"
      />
    ),
    cell: ({ row }) => {
      const amount = row.getValue('amount') as TransactionsTypes['amount']
      const formattedAmount = amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      const shares = row.getValue('shares') as TransactionsTypes['shares']
      const purchasePrice = row.getValue(
        'purchasePrice'
      ) as TransactionsTypes['purchasePrice']
      const formattedPurchasePrice = purchasePrice?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return (
        <div className="flex flex-col items-end">
          <div className="text-base">{formattedAmount}</div>
          {shares && purchasePrice && (
            <div className="text-[#8C8C8C] text-base">
              {shares} shares @ {formattedPurchasePrice} per share
            </div>
          )}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'foeType',
    meta: {
      filterLabel: 'All Ownership Types',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue(
        'foeType'
      ) as TransactionsTypes['foeType']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'foeName',
  },
  {
    accessorKey: 'investmentLink',
  },
  {
    accessorKey: 'issuer',
  },
  {
    accessorKey: 'shares',
  },
  {
    accessorKey: 'purchasePrice',
  },
]
