import { useEffect, useState } from 'react'
import { columns } from './components/columns.tsx'
import { DataTable } from './components/data-table.tsx'
import { z } from 'zod'
import { Taxes, taxesSchema } from './data/schema'
import TAXES_DATA from './data/taxes.json'
import ContactUs from '../shared/ContactUs'

// Simulate a database read for tasks.
async function getTasks() {
  return z.array(taxesSchema).parse(TAXES_DATA)
}

const TaxCenter = () => {
  const [tasks, setTasks] = useState<Taxes[]>([])

  useEffect(() => {
    getTasks().then(setTasks)
  }, [])

  return (
    <div className="px-[40px] w-full h-full">
      <DataTable data={tasks} columns={columns} />
      <ContactUs
        links={[
          {
            label: 'Questions? Send a message',
            location: 'https://realtymogul.com',
          },
          {
            label: 'Speak with Investor Relations',
            location: 'https://realtymogul.com',
          },
        ]}
      />
    </div>
  )
}

export default TaxCenter
