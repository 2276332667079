import { useQuery } from '@tanstack/react-query'

import { getDrupalUrl, getEmailVerifiedStatus } from '../shared/_api'

export const useEmailVerifiedDataState = () => {
  return useQuery({
    queryKey: ['emailVerified'],
    queryFn: getEmailVerifiedStatus,
    onSuccess: (data) => {
      if (!data.emailVerified) {
        window.location.href =
          getDrupalUrl() + 'please-verify-your-email-address'
      }
    },
    staleTime: Infinity,
  })
}
