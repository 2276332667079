import { useQuery } from '@tanstack/react-query'
import { datadogRum } from '@datadog/browser-rum'

import { getUser } from '../shared/_auth'
import { identify } from '../../utils/analytics'

declare global {
  interface Window {
    DD_RUM: any
  }
}

export const useAuthDataState = () => {
  return useQuery({
    queryKey: ['auth'],
    queryFn: getUser,
    onSuccess: (data) => {
      identify(data.attributes?.sub, data.attributes)
      datadogRum.setUser({
        name: data.attributes.given_name + ' ' + data.attributes.family_name,
      })
    },
    staleTime: Infinity,
  })
}
