import { cn } from '@/lib/utils'
import { Link } from 'react-router-dom'
import { buttonVariants } from '@/components/ui/button'

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'

interface NavProps {
  isCollapsed: boolean
  links: {
    title: string
    label?: string
    icon: string
    location: string
    active: boolean
  }[]
  links2: {
    title: string
    label?: string
    icon: string
    location: string
    active: boolean
  }[]
}

export function Nav({ links, links2, isCollapsed }: NavProps) {
  return (
    <div
      data-collapsed={isCollapsed}
      className={cn(
        'group flex flex-col gap-4 py-2 w-full h-full data-[collapsed=true]:py-2'
      )}
    >
      <nav
        className={cn(
          'grid rounded group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2',
          isCollapsed && 'gap-2'
        )}
      >
        {!isCollapsed && (
          <div className="text-base text-[#8C8C8C] px-4 mb-2">Dashboard</div>
        )}
        {links.map((link, index) =>
          isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <Link
                  to={link.location}
                  className={cn(
                    buttonVariants({ size: 'icon' }),
                    'h-9 w-9',
                    'bg-transparent hover:bg-slate-200',
                    link.active && 'bg-slate-200 dark:hover:text-white'
                  )}
                >
                  <i className={link.icon} />
                  <span className="sr-only">{link.title}</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right" className="flex items-center gap-4">
                {link.title}
                {link.label && (
                  <span className="ml-auto text-muted-foreground">
                    {link.label}
                  </span>
                )}
              </TooltipContent>
            </Tooltip>
          ) : (
            <Link
              key={index}
              to={link.location}
              className={cn(
                'justify-start bg-white flex items-center font-normal px-4 py-3 mr-4 rounded',
                link.active && 'bg-[#F9FAFB]'
              )}
            >
              <div
                className={cn(
                  'w-[18px] f-[18px] mr-3',
                  link.active
                    ? '[&>svg]:text-primary'
                    : '[&>svg]:text-content-black'
                )}
              >
                <i className={link.icon} />
              </div>
              {link.title}
              {link.label && (
                <span
                  className={cn(
                    'ml-auto',
                    link.active && 'text-[#1D89FF]',
                    'text-16'
                  )}
                >
                  {link.label}
                </span>
              )}
            </Link>
          )
        )}
        {!isCollapsed && (
          <div className="text-base text-[#8C8C8C] mt-4 px-4 mb-2">
            Settings
          </div>
        )}
        {links2.map((link, index) =>
          isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <Link
                  to={link.location}
                  className={cn(
                    buttonVariants({ size: 'icon' }),
                    'h-9 w-9',
                    'bg-transparent hover:bg-slate-200',
                    link.active && 'bg-slate-200 dark:hover:text-white'
                  )}
                >
                  <i className={link.icon} />
                  <span className="sr-only">{link.title}</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right" className="flex items-center gap-4">
                {link.title}
                {link.label && (
                  <span className="ml-auto text-muted-foreground">
                    {link.label}
                  </span>
                )}
              </TooltipContent>
            </Tooltip>
          ) : (
            <Link
              key={index}
              to={link.location}
              className={cn(
                'justify-start bg-white flex items-center font-normal px-4 py-3 mr-4 rounded',
                link.active && 'bg-[#F9FAFB]'
              )}
            >
              <div className="w-[18px] f-[18px] mr-3">
                <i
                  className={link.icon}
                  style={{
                    width: '100%',
                    height: '100%',
                    color: link.active ? '#1D89FF' : '#202020',
                  }}
                />
              </div>
              {link.title}
              {link.label && (
                <span
                  className={cn(
                    'ml-auto',
                    link.active && 'text-[#1D89FF]',
                    'text-16'
                  )}
                >
                  {link.label}
                </span>
              )}
            </Link>
          )
        )}
      </nav>
    </div>
  )
}

export default Nav
