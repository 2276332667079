import { ColumnDef } from '@tanstack/react-table'
import { cn } from '@/lib/utils'

import { Taxes } from '../data/schema'
import { DataTableColumnHeader } from './data-table-column-header'

import * as Tooltip from '@radix-ui/react-tooltip'

export const columns: ColumnDef<Taxes>[] = [
  // {
  //   id: 'select',
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && 'indeterminate')
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: 'taxYear',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tax Year" />
    ),
    cell: ({ row }) => {
      const rowStatusData = row.getValue('status') as Taxes['status']
      return (
        <div
          className={cn(
            rowStatusData.href ? '' : 'text-[#8C8C8C]',
            'font-medium text-base'
          )}
        >
          {row.getValue('taxYear')}
        </div>
      )
    },
    enableSorting: true,
    enableHiding: false,
    meta: {
      filterLabel: 'All Tax Years',
      reverseSort: true,
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue('taxYear') as Taxes['taxYear']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'document',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Document" />
    ),
    cell: ({ row }) => {
      const rowStatusData = row.getValue('status') as Taxes['status']
      return (
        <div
          className={cn(
            rowStatusData.href ? '' : 'text-[#8C8C8C]',
            'font-medium text-base'
          )}
        >
          {row.getValue('document')}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Documents',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue('document') as Taxes['document']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'investments',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Investment" />
    ),
    cell: ({ row }) => {
      const rowStatusData = row.getValue('status') as Taxes['status']
      const rowInvestmentsData = row.getValue(
        'investments'
      ) as Taxes['investments']
      return rowInvestmentsData.href.length > 1 ? (
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div
                className={cn(
                  rowStatusData.href ? '' : 'text-[#8C8C8C]',
                  'w-fit cursor-pointer underline text-base'
                )}
              >
                {rowInvestmentsData.label}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="bg-white p-6 text-base rounded shadow-[0px_3px_30px_0px_rgba(96,96,96,0.25)]">
                <div className="font-medium mb-3">{`${rowInvestmentsData.href.length} investments`}</div>
                <ul className="list-disc pl-6">
                  {rowInvestmentsData.href.map((href, index) => (
                    <li key={index} className="mb-2">
                      <a
                        className="underline cursor-pointer text-base"
                        href={href}
                        target="_blank"
                      >
                        {href}
                      </a>
                    </li>
                  ))}
                </ul>
                <Tooltip.Arrow className="fill-white" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      ) : (
        <a
          className={cn(
            rowStatusData.href ? '' : 'text-[#8C8C8C]',
            'underline cursor-pointer text-base'
          )}
          href={rowInvestmentsData.href[0]}
          target="_blank"
        >
          {rowInvestmentsData.label}
        </a>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Investments',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue(
        'investments'
      ) as Taxes['investments']
      return (
        rowInvestmentsData.label.includes(filterValue) || filterValue === 'all'
      )
    },
  },
  {
    accessorKey: 'ownershipType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ownership Type" />
    ),
    cell: ({ row }) => {
      const rowStatusData = row.getValue('status') as Taxes['status']
      return (
        <div
          className={cn(
            rowStatusData.href ? '' : 'text-[#8C8C8C]',
            'text-base'
          )}
        >
          {row.getValue('ownershipType')}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Ownership Types',
    },
    filterFn: (row, _columnId, filterValue) => {
      const rowInvestmentsData = row.getValue(
        'ownershipType'
      ) as Taxes['ownershipType']
      return rowInvestmentsData.includes(filterValue) || filterValue === 'all'
    },
  },
  {
    accessorKey: 'issuer',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Issuer" />
    ),
    cell: ({ row }) => {
      const rowStatusData = row.getValue('status') as Taxes['status']
      return (
        <div
          className={cn(
            rowStatusData.href ? '' : 'text-[#8C8C8C]',
            'text-base'
          )}
        >
          {row.getValue('issuer')}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center min-w-[150px]"
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => {
      const rowStatusData = row.getValue('status') as Taxes['status']
      return rowStatusData.href ? (
        <a
          className="flex underline justify-center text-[#0070EB] text-base"
          href={rowStatusData.href}
          target="_blank"
        >
          Download
        </a>
      ) : (
        <div className="text-center text-[#8C8C8C] underline text-base">
          {rowStatusData.label}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
]
