interface ContactUsProps {
  links: {
    label: string
    location: string
  }[]
}

export const ContactUs = ({ links }: ContactUsProps) => {
  return (
    <div className="w-full flex border-t border-[#E7E7E7] pt-6 items-center">
      <div className="text-[17px] font-medium">Contact us</div>
      <div className="ml-6 w-1/2 ">
        {links.map((link, index) => (
          <a
            className="text-[14px] leading-[16px] px-4 py-2 cursor-pointer inline-flex items-center"
            key={index}
            href={link.location}
          >
            <span>{link.label}</span>
            <i className="text-primary w-2 h-4 ml-2 fa-regular fa-angle-right" />
          </a>
        ))}
      </div>
    </div>
  )
}

export default ContactUs
